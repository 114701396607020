import React from 'react'
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import FAQ from '../../components/FAQ'
import { Link } from 'gatsby'
import styles from '../../components/layout.module.scss'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'

class Page extends React.Component {

    render() {

        return (

            <Layout location={this.props.location}>

                <SEO
                    title="How do podcasts make money in 2021?"
                    description="Use our guide to learn how the best podcasts are monetizing though Paid Sponsorships, Ads, Donations, merchandise and more. In this guide we will run you though the key commercial tactics"
                />

                <article>
                    <h1>How do podcasts make money?</h1>

                    <p>The world has gone completely and utterly podcast crazy. It seems like almost everyone has a podcast these days, and the charts are saturated with so many different ones talking about anything from sports to ancient history.&nbsp;</p>
                    <p>It&rsquo;s easy to see why they&rsquo;ve become so popular; people enjoy listening to them. There&rsquo;s no effort involved when you put a podcast on - it&rsquo;s a great way to pass the time. From a production standpoint, it can also be incredibly easy to set a podcast up on your own. You just need some recording equipment, topics to discuss, and then you&rsquo;re basically just chatting away with your friends or co-hosts.&nbsp;</p>
                    <p>There are many examples of people who started a podcast and have gone on to enjoy a lot of success. This brings up one of the most commonly asked questions by people looking to get involved in this sector; how much money do podcasts make? It varies depending on the podcast, but what's really interesting is how podcasts make money.&nbsp;</p>
                    <p>Bearing that in mind, this article will explain all the main ways you can make money from a podcast, along with a few tips to help you get started!</p>
                    <h2>Paid Sponsorships</h2>
                    <p>The majority of big podcasts will earn money through paid sponsorships. The premise is simple; a company comes to you and says they&rsquo;ll sponsor your podcast. In exchange for a regular fee, you essentially mention their brand as the sponsor, display their logo on the podcast image, and so on. Depending on the contract you have with the sponsor, they might request that you do other things as well, such as a segment in the podcast that puts more focus on their brand.&nbsp;</p>
                    <p>There are some pretty clear advantages and disadvantages of this money-making method. The advantage is that you usually sign a contract that guarantees you get a set amount of money, which can really help you invest back into the podcast and make it better. Plus, the brand power that the sponsor has could drive more listeners to your podcast. As a result, your audience rises, which leads to the sponsorship becoming more lucrative.&nbsp;</p>
                    <p>The downside is that most podcasts will only have one sponsor so you could limit how you make money from dealing with brands. Some companies are happy to sponsor you and allow you to run ads for other brands - as long as they don&rsquo;t interfere with their success. The other issue is that it can be hard to find sponsors when you&rsquo;re starting out.&nbsp;</p>
                    <h3>Tips for getting paid sponsorships</h3>
                    <p>We do have a few tips that might help you find sponsors for your podcast:&nbsp;</p>
                    <ul>
                        <li>Grow your audience: this is what will attract the attention of potential sponsors as they can tap into your large audience</li>
                        <li>Don&rsquo;t be afraid to contact brands: message companies that you think would be a good fit for your podcast, if they&rsquo;re made aware of you, they might be inclined to sponsor you</li>
                        <li>Look at who the main podcast sponsors are: have a look at other podcasts like yours to see what brands are sponsoring them. This helps you with the tip above as it can give you a list of businesses more likely to agree to a deal</li>
                    </ul>
                    <h2>Advertising</h2>
                    <p>This is similar to sponsorships in that you&rsquo;re basically advertising other companies. But, it&rsquo;s different because you don&rsquo;t get one brand to sponsor your podcast, you just run adverts on it. You can contact companies and tell them you&rsquo;ve got ad space on your podcast for them to buy, and they may purchase it. Basically, this means you run their ads at certain points, almost like commercial radio.&nbsp;</p>
                    <p>Alternatively, you can get adverts automatically on your podcast by signing up to specific platforms. For example, if you upload and host your podcast on YouTube, then you can apply for a partnership, which will monetize your videos. Google finds suitable adverts and puts them at different points in your broadcast. You then get paid based on how many views your podcast generates.&nbsp;</p>
                    <p>It can be argued that this is one of the best ways to supercharge your podcast earnings. There&rsquo;s no real limit to how many adverts you can have on your pod, and you continue to make money every time someone watches it. So, a podcast could be a year old, but you still generate ad revenue from it.&nbsp;</p>
                    <p>The only negative is that you won&rsquo;t really earn much until you start bringing in good, consistent, viewing figures. Also, some audience members could be put off by the adverts, but they usually just get used to them.&nbsp;</p>
                    <h3>Tips for making money through advertising</h3>
                    <p>If you want to try and make money through advertising other companies on your pod, then these are some of the best tips:</p>
                    <ul>
                        <li>Don&rsquo;t overdo it and stuff your podcast full of adverts. In the long run, less is more as it will help you retain an audience</li>
                        <li>Upload your podcast on various platforms that provide adverts for you</li>
                        <li>Think about hosting your pod on a dedicated website, where you can have online advertisements on the page. Thus, you don&rsquo;t interrupt the audience experience</li>
                    </ul>
                    <h2>Crowdfunding/Donations</h2>
                    <p>Crowdfunding has quickly become a prevalent way of making money from a podcast. It works in two ways. Firstly, you crowdfund to cover the costs of running the podcast and keep any extra as a source of income. Or, you use the money from crowdfunding to entirely cover the running costs, which allows you to make more money from other methods like sponsorships and advertising! Crowdfunding is basically the act of asking people to donate money so you can afford to create your podcast and churn out more episodes.&nbsp;</p>
                    <p>You can also ask listeners to donate money if they enjoy your content. It&rsquo;s their way of supporting you and showing that they love what you&rsquo;re doing. If you have some very generous listeners, then you could earn a fair bit of extra money!&nbsp;</p>
                    <p>One key advantage of crowdfunding/donations is that you don&rsquo;t have to work with any brands. So, you don&rsquo;t have to be restricted by sponsorship contracts, and you might not have to annoy audience members with adverts.&nbsp;</p>
                    <p>On the flip side, it&rsquo;s not going to bring in much money. Crowdfunding can actually work really well - some podcasts rake in a lot of cash that can primarily fund the whole production for a year or more. But, most of this money goes on running the podcast, so you don&rsquo;t actually &lsquo;make&rsquo; a lot from it, particularly compared to other methods.&nbsp;</p>
                    <h3>Crowdfunding/donation tips</h3>
                    <p>To make money from this method, we suggest the following:&nbsp;</p>
                    <ul>
                        <li>Give your audience something for the contribution - in essence, when they donate money, they benefit from it with a free gift, exclusive content, etc. It will encourage more people to donate.&nbsp;</li>
                        <li>Create a Patreon - this is a platform that&rsquo;s pretty much set up for people to accept donations in exchange for audience benefits. It&rsquo;s a secure way of doing things, meaning people are more inclined to participate.</li>
                        <li>Be transparent about where the money is going - if you&rsquo;re just crowdfunding to make money, then you have to tell people that. Don&rsquo;t act like it&rsquo;s going towards the podcast when it all goes into your bank account!</li>
                    </ul>
                    <h2>Selling merchandise/content</h2>
                    <p>Another way to make money from a podcast is by selling your own merchandise or content. Loads of podcasts created branded merch - be it shirts, jumpers, coasters, mugs - and sell them to their audience. If you can keep the cost of production as low as can be, then it&rsquo;s a very smart way to make money while giving your audience a way to connect with the podcast.&nbsp;</p>
                    <p>Similarly, you could also sell your content. Most podcasts are free, but you could do a few special episodes that people have to pay for. This coincides with the whole donation thing mentioned above. Set up an exclusive club for your most dedicated members, and they can pay to get content that more casual listeners will never get to listen to.&nbsp;</p>
                    <p>Both of these ideas will bring in a lot of extra money, but the downside is that it costs money to create and distribute merchandise. It&rsquo;ll also cost more to make your exclusive content, and there&rsquo;s no guarantee people will buy either. So, you could pay to get all of this stuff, then end up with way too much. However, if people to purchase your merch, then you can make a fortune.&nbsp;</p>
                    <h3>Tips for selling merchandise</h3>
                    <p>Selling merchandise is a proven method of making money. It&rsquo;s something we see all over the world, and not just in the podcast domain. TV shows sell merch, movies create merch, and so on. If you want to make as much money as possible from this idea, then here are our tips:</p>
                    <ul>
                        <li>Create merchandise people actually want to buy</li>
                        <li>Sell it for a reasonable price, but ensure you can still make a profit</li>
                        <li>Set-up a professional website to sell the merch through, this encourages more buyers as it looks more like a legitimate business</li>
                        <li>Keep releasing new merch as your podcast grows, giving people an excuse to buy more and more!</li>
                    </ul>
                    <h2>Live Shows</h2>
                    <p>Part of what makes podcasts enjoyable is the feeling of connecting with the hosts. There&rsquo;s an intimacy to it that you don&rsquo;t really get with other mediums - audiences will connect with the hosts of their favorite podcasts. As a result, this relationship can be used to make more money from your pod.</p>
                    <p>Live shows are a brilliant idea as they can bring in a lot of revenue. The idea is simple; you book a venue and sell tickets for people to come down and witness the podcast live and in-person. It&rsquo;s a rare chance for them to meet you, and they really feel like part of the podcast. If you have dedicated fans, this is such a fantastic concept as they&rsquo;ll love them.&nbsp;</p>
                    <p>Naturally, the cost and effort of hosting a live show mean you&rsquo;ll have to do them sparingly. But, think about all the money you bring in through ticket sales - and you can even get sponsors for the event as well.&nbsp;</p>
                    <p>Having said that, this is an option you should consider later on in your podcast lifecycle. Wait until you&rsquo;ve established a loyal audience, or else no one will attend the events.&nbsp;</p>
                    <h3>Tips for making money from live shows</h3>
                    <p>If you want to set up a live show and start selling tickets, then here are a few ways you can maximize the revenue generated:&nbsp;</p>
                    <ul>
                        <li>Make sure you ask your audience if they&rsquo;d be interested, so you can see how many people would be willing to turn up</li>
                        <li>Ensure the live show is unique and that they really gain something from being there - perhaps they can meet you, come on stage, etc.&nbsp;</li>
                        <li>Make it a special event a few times per year, and keep it exclusive - people will miss out on one live show, meaning the demand for the next one is even higher</li>
                    </ul>
                    <h2>Affiliate programs</h2>
                    <p>Lastly, we have an idea that&rsquo;s very similar to sponsorships/adverts, but the way you make money is different. You can sign up to an affiliate program, and you get given a link to share. During the podcast, you can mention the products from this brand, and direct people to your affiliate link. If people click on the link and go on to buy something from the site, you get a cut of the sale.&nbsp;</p>
                    <p>It may surprise you how much money you can make doing this! This concept is typically used by bloggers, but it works for podcasts too.&nbsp;</p>
                    <h3>Tips for making money from affiliate programs</h3>
                    <p>To make the most out of this idea, follow these tips:</p>
                    <ul>
                        <li>Find programs that align with your podcast audience - this makes it more likely they&rsquo;ll want to buy something</li>
                        <li>Only promote products/brands you believe in</li>
                        <li>Show your audience how they can benefit from the products to entice them into a purchase</li>
                    </ul>


                    <section>
                        <GetStartedOrChat cta="Ready to get more downloads, increasing your podcasts revenue? Start sharing your podcast today!" />
                        <br/>
                    </section>

                    <section>
                        <br/>
                        <h2>Frequently Asked Questions</h2>
                        <FAQ items={[
                            {
                                'question': 'How do you make money from podcasts?',
                                'answer': 'You can make money from your free podcast thought sponsorships, ads, merch, Affiliate programs & Live Shows. Revenue will scale as you increase your downloads and listens.'
                            },
                            {
                                'question': 'How do podcasts without ads make money',
                                'answer': 'Adfree shows have alternative methods for generating revenue such as Live Shows, selling Merch (Hoodies, T-shirts, Water Bottles).'
                            },
                            {
                                'question': 'How do youtube podcasters make money',
                                'answer': 'Youtube makes money for podcasts via targeted adverts, this can be supplemented with viewer donations & Merch'
                            },
                            {
                                'question': 'How much money do podcasts make?',
                                'answer': 'How much money can you make doing a podcast varies dramatically depending on the source of revenue (Ads/Sposorship/Merch), the season (holiday season yields more), the topic and target audience? Podcast revenue can range from making zero income to tens of dollars per thousand listens.'
                            },
                            {
                                'question': 'How do podcast networks make money',
                                'answer': 'Podcast networks make money in the same way podcats do, with sponsorships and ads. They have the advantage of economy of scale combined with being able to cross-sell on other podcasts in the network. Some networks such as the BBC are taxpayer funded.'
                            },
                        ]} />

                    </section>

                    <section>
                        <h2>Summary</h2>
                        <p>To conclude, podcasts make money in many different ways. Our overall advice is to explore as many possibilities as possible. Find a sponsor that lets you run other adverts on your podcast, sell merchandise, and set up live shows. By using a combination of ideas, you can maximize the amount of money you make.</p>
                    </section>

                </article>


            </Layout>

        )
    }

}

export default Page
